<template>
  <div id="map" ref="mapContainer"></div>
</template>

<script>
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import settingMap from "@/utils/map/settingMap.js";
import { deleteMapContributors } from "@/utils/map/deleteMapContributors";
import { createRoute } from "@/utils/map/createRoute.js";
export default {
  props: ["order", "orderHistory"],
  data() {
    return {
      map: null,
      markerHome1: null,
      markerHome2: null,
      center: [this.order.from_address.lng, this.order.from_address.lat],
      geo_json_array: null,
    };
  },
  mounted() {
    this.refresh();
    deleteMapContributors();
  },
  methods: {
    refresh() {
      this.map = new maplibregl.Map({
        container: this.$refs.mapContainer,
        style: settingMap.map.style,
        zoom: settingMap.map.zoom,
        maxZoom: settingMap.map.maxZoom,
        minZoom: settingMap.map.minZoom,
        center: this.center,
      });
      this.map.on("load", () => {
        this.map.addSource("map", {
          type: "vector",
          tiles: ["https://mtile.gram.tj/data/v3/{z}/{x}/{y}.pbf"],
          cluster: true,
        });
        let data = JSON.parse(this.order.geo_json_array);
        createRoute(data, this.order.id, this.map);
        this.createMarker();
      });
    },
    createMarker() {
      const popup = new maplibregl.Popup({ offset: 25 }).setText(
        `${this.order.from_address.address + `(${this.order.created_at})`}`
      );
      const el = document.createElement("div");
      el.style.width = "40px";
      el.style.height = "40px";
      el.style.backgroundImage = `url(${settingMap.map.home})`;
      el.style.backgroundSize = "cover";
      el.style.Cursor = "pointer";

      const numberEl = document.createElement("div");
      numberEl.textContent = "1";
      numberEl.style.position = "absolute";
      numberEl.style.top = "-1px";
      numberEl.style.right = "1px";
      numberEl.style.color = "white";
      numberEl.style.fontSize = "12px";
      numberEl.style.fontWeight = "bold";
      numberEl.style.backgroundColor = "black";
      numberEl.style.borderRadius = "50px";
      numberEl.style.padding = "3px 8px";
      el.appendChild(numberEl);

      this.markerHome1 = new maplibregl.Marker({ element: el })
        .setLngLat([this.order.from_address.lng, this.order.from_address.lat])
        .setPopup(popup)
        .addTo(this.map);

      this.order.to_addresses.forEach((e, index) => {
        const popup = new maplibregl.Popup({ offset: 25 }).setText(
          `${e.address + `(${e.created_at ? e.created_at : "Нет данные"})`}`
        );
        const el = document.createElement("div");
        el.style.width = "40px";
        el.style.height = "40px";
        el.style.backgroundImage = `url(${settingMap.map.home})`;
        el.style.backgroundSize = "cover";
        el.style.Cursor = "pointer";

        const numberEl = document.createElement("div");
        numberEl.textContent = `${index + 2}`;
        numberEl.style.position = "absolute";
        numberEl.style.top = "-1px";
        numberEl.style.right = "1px";
        numberEl.style.color = "white";
        numberEl.style.fontSize = "12px";
        numberEl.style.fontWeight = "bold";
        numberEl.style.backgroundColor = "black";
        numberEl.style.borderRadius = "50px";
        numberEl.style.padding = "3px 8px";
        el.appendChild(numberEl);

        this.markerHome2 = new maplibregl.Marker({ element: el })
          .setLngLat([e.lng, e.lat])
          .setPopup(popup)
          .addTo(this.map);
      });
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  },
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: calc(100vh - 98px) !important;
}
</style>
